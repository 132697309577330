import { IconButton } from '@mui/material';
import React from 'react';
import Spotify from '../img/spotify-white.png';
import { Facebook, Instagram, Reddit, Twitter } from '@mui/icons-material';
import CustomTooltip from './CustomTooltip';
import { ErrorBoundary } from './ErrorBoundary';
import AppleMusicIcon from '../img/Apple Music/Apple_Music_Badge/SVG/US-UK_Apple_Music_Listen_on_Badge_RGB_072720.svg';
export default function SocialLinks() {
	const musicLinks = [
		{
			name: 'Spotify',
			href: 'https://open.spotify.com/album/0SrHnEsNSX4UorjBRNlNbF?si=-0q_WOOPTNKbc4fPhEWijg',
			icon: (
				<img
					style={{
						width: 125,
						display: 'block',
						color: 'white',
					}}
					src={Spotify}
					alt='spotify-logo'
				/>
			),
		},
		{
			name: 'Apple Music',
			href: 'https://music.apple.com/us/album/back-in-town/1661698251',
			icon: <img src={AppleMusicIcon} alt='Apple Music Logo' />,
		},
	];
	const social = [
		{
			name: 'Facebook',
			href: 'http://Facebook.com/DogDreamsOfficial',
			icon: <Facebook />,
		},
		{
			name: 'Instagram',
			href: 'https://www.instagram.com/dogdreamsband/',
			icon: <Instagram />,
		},
		{
			name: 'Twitter',
			href: 'https://twitter.com/DogDreamsBand',
			icon: <Twitter />,
		},
		{
			name: 'Reddit',
			href: 'https://www.reddit.com/r/DogDreamsBand/',
			icon: <Reddit />,
		},

		// {
		// 	name: 'Mastodon',
		// 	href: 'https://mastodon.world/@dogdreams/109526887286638378',
		// 	icon: <Mastodon />,
		// },
	];
	return (
		<div>
			<ErrorBoundary>
				<div className='social'>
					<div>
						{musicLinks
							? musicLinks.map((site) => (
									<a
										href={site.href}
										target='_blank'
										key={site.name}
									>
										<IconButton
											size='large'
											style={{ color: 'white' }}
										>
											{site.icon}
										</IconButton>
									</a>
							  ))
							: null}
					</div>
					{social
						? social.map((site) => (
								<CustomTooltip text={site.name} key={site.name}>
									<a href={site.href} target='_blank'>
										<IconButton
											size='large'
											style={{ color: 'white' }}
										>
											{site.icon}
										</IconButton>
									</a>
								</CustomTooltip>
						  ))
						: null}
				</div>
			</ErrorBoundary>
		</div>
	);
}
