import { Box, Typography } from '@mui/material';
import './App.css';
import Cover from './img/artist-pic-dark.png';
import SocialLinks from './components/SocialLinks';
import Album from './components/Album';
import AppleMusicEmbed from './components/AppleMusicEmbed';
import MediaEmbed from './components/MediaEmbed';
import AppleMusicSection from './components/AppleMusicSection';
import MusicPlayer from './components/MusicPlayer';
function App() {
	const styles = {
		text: {
			color: 'white',
			fontFamily: 'Fjalla One, sans-serif',
		},
		backgroundImage: {
			backgroundImage: `url(${Cover})`,
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
		},
	};

	return (
		<div
			className='App'
			style={styles.backgroundImage}
		>
			<div className='titles'>
				<Typography
					style={{
						...styles.text,
					}}
					variant='h1'
				>
					Dog Dreams
				</Typography>
				<Box sx={{ display: { xs: 'none', md: 'block' } }}>
					{/* <AppleMusicSection /> */}
					<MusicPlayer />
				</Box>
				<SocialLinks />
			</div>
		</div>
	);
}

export default App;
