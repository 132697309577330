import React, { useEffect, useState } from 'react';
import './player.css';
import { PlayArrow, FastRewind, FastForward, Pause } from '@mui/icons-material';
// artwork
import ARTWORK from './album-art.png';
// songs
import NEW_BEGINNINGS from './songs/01 New Beginnings.m4a';
import BACK_IN_TOWN from './songs/02 Back In Town.m4a';
import DOG_DREAMIN from './songs/03 Dog Dreaming.m4a';
import FILLERSONG from './songs/04 Filler Song.m4a';
import FRIENDSFIRES from './songs/05 Friends and Fires.m4a';
import SNOWDAY from './songs/06 Snow Day.m4a';
import AWAKE from './songs/07 Awake.m4a';
import USETHISVERSION from './songs/08 Use This Version.m4a';
import COLDCOMFORT from './songs/09 Cold Comfort.m4a';
import ICAN from './songs/10 I Can.m4a';
import FORGIVENESS from './songs/11 Forgiveness.m4a';
export default function MusicPlayer() {
	const [progressWidth, setProgressWidth] = useState(0);
	const [song, setSong] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const playlist = [
		{ title: 'New Beginnings', src: NEW_BEGINNINGS, artwork: ARTWORK },
		{ title: 'Back In Town', src: BACK_IN_TOWN, artwork: ARTWORK },
		{ title: 'Dog Dreaming', src: DOG_DREAMIN, artwork: ARTWORK },
		{ title: 'Filler Song', src: FILLERSONG, artwork: ARTWORK },
		{ title: 'Friends and Fires', src: FRIENDSFIRES, artwork: ARTWORK },
		{ title: 'Snow Day', src: SNOWDAY, artwork: ARTWORK },
		{ title: 'Awake', src: AWAKE, artwork: ARTWORK },
		{ title: 'Use This Version', src: USETHISVERSION, artwork: ARTWORK },
		{ title: 'Cold Comfort', src: COLDCOMFORT, artwork: ARTWORK },
		{ title: 'I Can', src: ICAN, artwork: ARTWORK },
		{ title: 'Forgiveness', src: FORGIVENESS, artwork: ARTWORK },
	];

	const [activeSongIndex, setActiveSongIndex] = useState(0);
	const loadSong = (activeSongIndex) => {
		setSong(playlist[activeSongIndex]);
		console.log({ song: playlist[activeSongIndex] });
		return;
	};
	const nextSong = (e) => {
		e.preventDefault();
		if (activeSongIndex >= playlist.length - 1) {
			setActiveSongIndex(0);
		} else {
			setActiveSongIndex(activeSongIndex + 1);
		}
	};
	const previousSong = (e) => {
		e.preventDefault();
		if (activeSongIndex > 0) {
			setActiveSongIndex(activeSongIndex - 1);
		} else {
			setActiveSongIndex(playlist.length - 1);
		}
	};
	const updateProgress = (e) => {
		e.preventDefault();

		const { duration, currentTime } = e.target;
		const progress = document.getElementById('progress');
		const progressPercentage = (currentTime / duration) * 100;
		progress.style.width = `${progressPercentage}%`;
		setProgressWidth(progressPercentage);
	};
	const playSong = (e) => {
		e.preventDefault();

		setIsPlaying(true);
		const audio = document.querySelector('#audio');
		audio.addEventListener('timeupdate', updateProgress);
		audio.addEventListener('ended', nextSong);
		// audio.volume = 0.3;
		audio.play();
		return;
	};
	const pauseSong = (e) => {
		e.preventDefault();

		setIsPlaying(false);
		const audio = document.querySelector('#audio');
		audio.pause();
		return;
	};
	const clickProgressBar = (e) => {
		const progressContainer = document.getElementById('progress-container');
		const audio = document.getElementById('audio');
		console.log({ progressContainer });

		const width = progressContainer.clientWidth;
		const clickX = e.nativeEvent.offsetX;
		const duration = audio.duration;
		audio.currentTime = (clickX / width) * duration;
		console.log({ width, clickX });
		return;
	};

	useEffect(() => {
		loadSong(activeSongIndex);
	}, [activeSongIndex]);

	return (
		<div id='parent'>
			<div
				id='music-container'
				className={
					isPlaying ? 'music-container play' : 'music-container'
				}
			>
				<div className='music-info'>
					<h4 id='title'>{song ? song.title : 'Pick a song'}</h4>
					<div
						className='progress-container'
						id='progress-container'
						onClick={clickProgressBar}
					>
						<div
							id='progress'
							className='progress'
						></div>
					</div>
				</div>
				<audio
					src={song ? song.src : './songs/Bop MST.m4a'}
					id='audio'
				></audio>
				<div className='img-container'>
					<img
						src={song ? song.artwork : null}
						alt='music-cover'
						id='cover'
					/>
				</div>
				<div className='navigation'>
					<button
						id='previous'
						className='action-btn'
						onClick={previousSong}
					>
						<FastRewind />
					</button>
					<button
						id='play'
						onClick={isPlaying ? pauseSong : playSong}
						className='action-btn action-btn-big'
						style={{ fontSize: 60 }}
					>
						{isPlaying ? <Pause /> : <PlayArrow />}
					</button>
					<button
						id='next'
						className='action-btn'
						onClick={nextSong}
					>
						<FastForward />
					</button>
				</div>
			</div>
		</div>
	);
}
