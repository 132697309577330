import React from 'react';
import Section from './containers/Section';
import { Container } from '@mui/material';
import SectionHeader from './containers/SectionHeader';
import AppleMusicEmbed from './AppleMusicEmbed';

function AppleMusicSection(props) {
	return (
		<Section
			bgColor={props.bgColor}
			size={props.size}
			bgImage={props.bgImage}
			bgImageOpacity={props.bgImageOpacity}
		>
			<Container>
				<SectionHeader
					title={props.title}
					subtitle={props.subtitle}
					textAlign='center'
				/>
				<Container maxWidth='sm' disableGutters={true}>
					<AppleMusicEmbed url='https://embed.music.apple.com/us/album/dog-dreaming/1661698251?i=1661698255' />
				</Container>
			</Container>
		</Section>
	);
}

export default AppleMusicSection;
